import { useState } from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import appleIcon from '../assets/images/apple.png'
import playstoreIcon from '../assets/images/android.png'
import androidVideo from '../assets/videos/android.mp4'
import ios from '../assets/videos/ios.mp4'

const Home = () => {
  
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const [activeTab, setActiveTab] = useState('android');
  const [videoSource, setVideoSource] = useState(androidVideo)


  const handler = (e) => {
    setActiveTab(activeTab === 'android' ? 'ios' : 'android')
    setVideoSource(videoSource === androidVideo ? ios : androidVideo)
  }

  return (
    <div className='overflow-hidden' >
      <Navbar />


      {/* <section className='flex flex-col items-center h-[30.26vw] ' id='section-1'></section>*/}

      <div className='bg-gradient-to-r from-[#000] to-[#161e33] '>
      <section className='sm:grid grid-cols-2 items-center justify-center px-5 sm:px-3  mx-auto md:max-w-[1440px] ' id='section-2'>
        <div className='lg:mb-8 sm:mt-16 pt-5 ' >
          <h1 className='lg:text-6xl text-3xl text-left lg:mb-8  text-white text-transparent ' ><span className='text-[#ffb700]'>BAHİSTE</span> ÇIĞIR AÇAN SEÇENEKLER</h1>
          <div className='h-[3px] w-32 bg-[#ffb700]' />
          <p className='lg:text-md mt-5 text-left  text-white ' >CASİNOZİGA'da yüzlerce farklı spor müsabakasına en iyi oranlarla bahis yapın, en güncel oyunları deneyimleyin ya da güncel slot oyunları ile çok hızlı bir şekilde kazanın! OlaBahis’in %100 güvenilir alt yapısı ve kullanıcı dostu arayüzü ile kazanmaya davetlisiniz. Ayrıca ilk üyelik bonusunuz hazır!</p>
        </div>
        <div className='relative pb-3' >
          <img src={require('../assets/images/section-1.png')} className='  mx-auto' alt='' />
        </div> 
      </section>
      </div>


      <div className='bg-gradient-to-r from-[#161e33] to-[#000] '>
      <section className='sm:grid grid-cols-2 items-center justify-center pt-3 px-5 sm:px-3  mx-auto md:max-w-[1440px] ' id='section-2'>
      <div className='relative    ' >
          <img src={require('../assets/images/dede.png')} className='mx-auto' alt='' />
        </div> 
        <div className='lg:mb-8 sm:mt-16 pt-5 ' >
          <h1 className='lg:text-6xl text-3xl text-left lg:mb-8  text-white text-transparent ' >Sınırsız Bahsin Keyfini Çıkar</h1>
          <div className='h-[3px] w-32 bg-[#ffb700]' />
          <p className='lg:text-md mt-5 text-left  text-white  pb-5' >Gelişmiş ve hızlı arayüzümüz ile kullanıcı dostu mobil uygulamamızla sizlerleyiz. Casinoziga mobil uygulaması daha iyi ve güvenli oyun deneyiminiz için tasarlandı.</p>
        </div>
      
      </section>
      </div>

      <div className=' bg-black '>
      <section className='sm:grid grid-cols-2 items-center justify-center px-5 sm:px-3  mx-auto md:max-w-[1440px]  pt-10 pb-10' id='section-2'>
        <div className='lg:mb-8 sm:mt-16  pt-5 ' >
          <h1 className='lg:text-5xl text-xl text-left lg:mb-8  text-white text-transparent ' >Kullanışlı, Güçlü Arayüz ve Çok ÇEŞİTLİ BAHİS SEÇENEKLERİ</h1>
          <p className='lg:text-md mt-5 text-left  text-white ' >Son derece sade ve kullanışlı arayüz ile tanışın! Canlı 
casino, slotlar,spor oyunları ve daha bir çoğu 
Casinoziga’da seni bekliyor.</p>
        </div>
        <div className='relative    ' >
          <img src={require('../assets/images/katman.png')} className='mx-auto  ' alt='' />
        </div> 
      </section>
      </div>

      <section className=' relative overflow-hidden  bg-gradient-to-b from-[#000] to-[#161e33] ' id='google-slider'>
        <div className='lg:mb-10 mb-5 px-5 mt-12' >
          <h3 className='lg:text-5xl text-3xl text-center font-semibold text-white ' > <span className='text-[#FFB800]'>CASINOZİGA</span> <br/>ANDROID’E  NASIL 
          İNDİRİLİR?</h3>
        </div>
            <div className='flex flex-col-2 items-center justify-center mb-10'>
                 <img src={require('../assets/images/android.png')} alt="" />
                 <h1 className=' text-4xl font-thin mr-2 ml-3 text-white'>ANDROID</h1>
             </div>  
        <div className='lg:px-36 px-5 ' >
          <Slider platform='android' />
        </div>
      </section>

      <section className=' relative overflow-hidden  bg-gradient-to-b from-[#161e33] to-[#000] ' id='google-slider'>
        <div className='lg:mb-10 mb-5 px-5 mt-12' >
          <h3 className='lg:text-5xl text-3xl text-center  text-white ' > <span className='text-[#FFB800]'>CASINOZİGA</span> <br/>IOS'A NASIL 
          İNDİRİLİR?</h3>
        </div>
            <div className='flex flex-col-2 items-center justify-center mb-10'>
                 <img src={require('../assets/images/apple.png')} alt="" />
                 <h1 className=' text-4xl font-thin mr-2 ml-3 text-white'>APPLE</h1>
             </div>  
        <div className='lg:px-36 px-5 ' >
          <Slider platform='android' />
        </div>
      </section>

  

  <section className='bg-gradient-to-b from-[#000] to-[#161e33] flex flex-col' >
      <h1 className='lg:text-6xl text-3xl text-center  lg:mb-16  text-white text-transparent mt-20 sm:mb-0 mb-5' >CASINOZIGA NASIL İNDRİLİLİR?
<br /><span className=' font-light'>VİDEO</span></h1>

        <div className='m-auto flex gap-5 p-1 rounded-2xl border-2 bg-gray mb-10'  style={{ borderColor: '#FBCA34' }}>

          <div
            className={`flex justify-center p-3 rounded-2xl ${activeTab === 'android' ? 'bg-white hover:bg-' : ''}`}
            onClick={handler}>
            <img
              src={playstoreIcon}
              className='mr-3 text-white h-12 w-12 self-center'
            />
            <p className='uppercase  text-[#FBCA34] self-center'onClick={handleClick}
        style={{ color: active ? "#FBCA34" : "#FBCA34" }} >android</p>
          </div>


          <div
            className={`flex justify-center p-3 rounded-2xl ${activeTab === 'ios' ? 'bg-white hover:bg-white ' : ''}`}
            onClick={handler} >
            <img
              src={appleIcon}
              className='mr-3 h-12 w-12 self-center'
            />
            <p className='uppercase  self-center ' onClick={handleClick}
        style={{ color: active ? "#FBCA34" : "#FBCA34" }}>APPLE</p>
          </div>
        </div>
       
        <div className='mb-10 px-5' >
            <Player source={videoSource} key={activeTab}/>
          </div>

      </section>

      <footer className='bg-[#161e33] h-auto justify-center items-center pb-4 ' >
         
        
         <img src={require('../assets/images/logo-var1.png')} className='pt-4 mb-4 w-72 mx-auto' alt='' />
            
             <div className=' mx-auto '>
              <div className='max-w-100 flex flex-col-3   gap-2 sm:gap-5  items-center justify-center pb-2'>
                <img src={require('../assets/images/Vector(6).png')} className='' alt='' />
                <img src={require('../assets/images/Vector(7).png')} className='' alt='' />
                <img src={require('../assets/images/Vector(8).png')} className='' alt='' />
                </div>     
                <p className='text-[#DDDDDD] self-center text-center '>© Copyright © 2024 by CasinoZiga</p>

            </div>
         
      </footer>

    </div>
  )
}

export default Home

const Player = ({ key, source }) => {
  return (
    <video key={key} class="lg:w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}

import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Slider = ({ platform = 'ios' }) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 0 },
            items: 1
        },
    };
    const imgStyle = {
        width: '100%',
        maxWidth: '230px',
        height: 'auto',
    }

    return (
        <div className='p-2' >
            <Carousel responsive={responsive}
                showDots={false}
                centerMode={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                itemClass='p-2'
            >
                <div>
                    <img src={require(`../assets/images/${platform}/carousel-1.png`)} style={imgStyle} alt='sekabet screenshot 1' />
                </div>
                <div>
                    <img src={require(`../assets/images/${platform}/carousel-2.png`)} style={imgStyle} alt='sekabet screenshot 2' />
                </div>
                <div>
                    <img src={require(`../assets/images/${platform}/carousel-3.png`)} style={imgStyle} alt='sekabet screenshot 3' />
                </div>
                <div>
                    <img src={require(`../assets/images/${platform}/carousel-4.png`)} style={imgStyle} alt='sekabet screenshot 4' />
                </div>
               
            </Carousel>
        </div>
    )
}


export default Slider;